import React from "react";

const ParticipationBar = ({ bgcolor, completed }) => {
	const fillerStyles = {
		height: "100%",
		width: `${completed}%`,
		backgroundColor: bgcolor,
		borderRadius: "inherit",
		textAlign: "right",
	};
	return (
		<div className="containerStyles">
			<div style={fillerStyles}>
				<span className="labelStyles"></span>
			</div>
		</div>
	);
};

export default ParticipationBar;
