import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ParticipationBar from "../layout/ParticipationBar";

import {
	setCurrQuizID,
	setCurrQuiz,
	setPlayersCount,
	setHasJoined,
} from "../../actions/curr_contest";
import { addParticipation } from "../../actions/curr_contest";
import { setAlert } from "../../actions/alert";

import Web3 from "web3";

const QuizItem = ({
	auth: { user, token_count, contract },
	quiz: {
		_id,
		image_url,
		contest_name,
		prize_pool,
		max_players,
		current_players,
		entry_fee,
		start_time,
		questions,
	},
	curr_contest: { curr_quiz_id, curr_quiz, currP, maxP, has_joined },
	setCurrQuizID,
	setCurrQuiz,
	setPlayersCount,
	addParticipation,
	setHasJoined,
	setAlert,
}) => {
	const [redirectNow, setRedirectNow] = useState(false);
	const [discount, setDiscount] = useState(0);
	const [bonus, setBonus] = useState(0);

	const [web3, setWeb3] = useState(null);
	const [accountsData, setAccountsData] = useState(null);

	const millisToMinutesAndSeconds = (millis) => {
		var hours = Math.floor(millis / 3600000);
		var minutes = Math.floor((millis % 3600000) / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);

		if (hours === 0) {
			return seconds === 60
				? minutes + 1 + "m 00s"
				: minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
		} else {
			return minutes === 60
				? hours + 1 + "hr 00m"
				: hours + "hr " + minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
		}
	};

	const real_time = new Date();
	const contest_time = new Date(start_time);

	const [left_time, setLeft_time] = useState(Math.max(contest_time - real_time, 0));

	useEffect(() => {
		let timer1 = setTimeout(() => {
			setLeft_time(Math.max(contest_time - new Date(), 0));
		}, 1000);
		return () => clearTimeout(timer1);
	});

	const [payment_result, setPayment_result] = useState(null); //NOTE : Comment FOR TESTING WITHOUT PAYMENT
	// const [payment_result, setPayment_result] = useState(true); //NOTE : Uncomment FOR TESTING WITHOUT PAYMENT

	const loadWeb3 = async () => {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
		}
		if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			window.alert("Please install MetaMask!");
		}
		setWeb3(window.web3);
		const web3 = window.web3;
		// const accounts = await web3.eth.getAccounts();
		const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
		setAccountsData(accounts);
		// const contract = new web3.eth.Contract(token, "0xeaA2F739552A4E10b336CD09142BCaAd20EA1d3d");
	};

	const pay_fee_contract = async (joining_fee) => {
		// const token_count = await contract.methods.balanceOf(user).call();
		// console.log(token_count);
		try {

			console.log('Contract is---->', contract)
			const res = await contract.methods
				.transfer(
					"0x991AB53c8Aee380404f10951F22d59a8Fb204c57",
					joining_fee.toString() + "000000000000000000"
				)
				.send({ from: user });
			console.log("Payment Successful!");
			if (res.status === true) {
				setPayment_result(true);
				addParticipation({ user_address: user, contest_id: curr_quiz_id });
				setHasJoined();
				setTimeout(
					() => setAlert("Contest is about to start...", "start"),
					new Date(curr_quiz.start_time) - new Date()
				);
				setTimeout(
					() => setRedirectNow(true),
					new Date(curr_quiz.start_time) - new Date() + 3 * 1000
				);
			} else {
				setPayment_result(false);
			}
		} catch (error) {
			console.log("caught", error);
			setPayment_result(false);
		}
	};

	const onSelection = () => {
		setCurrQuiz(curr_quiz_id);
		//NOTE: Comment for testing
		pay_fee_contract(entry_fee);
		//NOTE: Uncomment for testing
		// setTimeout(() => setRedirectNow(true), 3000);
	};

	const popupHandler = (_id) => {
		setCurrQuizID(_id);
		setCurrQuiz(_id);
		setPlayersCount(current_players, max_players);
	};

	return redirectNow ? (
		<Redirect to="/livecontest" />
	) : (
		<div className="cntlst_box_reptr">
			<div className="cntlst_media">
				{image_url ? (
					<img className="h_logo" src={image_url} alt="" />
				) : (
					<i className="fas fa-trophy fa-5x"></i>
				)}
			</div>

			<div className="cntlst_contnt">
				<div className="gq_b">
					<div className="gq_b_h left">
						<h3>{contest_name}</h3>
						<p>{questions.length} Question(s)</p>
					</div>
					<div className="gq_b_h right">
						<h3>
							<img
								style={{ maxWidth: "20px" }}
								src="image/rummy_rupee_coins.png"
								alt=""
							/>{" "}
							{prize_pool}
						</h3>
						<p>Prize Pool</p>
					</div>
				</div>
				<div className="progress_box">
					<ParticipationBar
						bgcolor="#F1C40F"
						completed={Math.floor((current_players / max_players) * 100)}
					/>
					<div className="progress_player">
						<div className="pp_box left">
							<h3>{current_players} Players</h3>
						</div>
						<div className="pp_box right">
							<h3>{max_players} Players</h3>
						</div>
					</div>
				</div>
				<div className="clkfeeprs">
					<p className="post-date clock">
						<i className="far fa-clock"></i>&nbsp;
						{left_time !== null ? millisToMinutesAndSeconds(left_time) : "--:--"}
					</p>
					<div className="fee_price">
						<div className="b_fee">
							{has_joined === true && curr_quiz_id === _id ? "" : <h3>Entry Fee</h3>}
						</div>
						<div className="b_price">
							{current_players < max_players && left_time > 0 ? (
								has_joined === false ? (
									<button
										onClick={() => popupHandler(_id)}
										type="button"
										className="green_coin_buttn"
										data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasBottom"
										aria-controls="offcanvasBottom"
									>
										<span>
											<img
												style={{ maxWidth: "20px" }}
												src="image/rummy_rupee_coins.png"
												alt=""
											/>{" "}
											{entry_fee}
										</span>
									</button>
								) : curr_quiz_id === _id ? (
									<button
										disabled
										type="button"
										className="disabled_join_button"
										data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasBottom"
										aria-controls="offcanvasBottom"
									>
										<span>JOINED</span>
									</button>
								) : (
									<button
										disabled
										type="button"
										className="disabled_green_join_button"
										data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasBottom"
										aria-controls="offcanvasBottom"
									>
										<span>
											<img
												style={{ maxWidth: "20px" }}
												src="image/rummy_rupee_coins.png"
												alt=""
											/>{" "}
											{entry_fee}
										</span>
									</button>
								)
							) : (
								<button
									disabled
									type="button"
									className="disabled_join_button"
									data-bs-toggle="offcanvas"
									data-bs-target="#offcanvasBottom"
									aria-controls="offcanvasBottom"
								>
									<span>
										{has_joined === true && curr_quiz_id === _id ? (
											""
										) : (
											<img
												style={{ maxWidth: "20px" }}
												src="image/rummy_rupee_coins.png"
												alt=""
											/>
										)}{" "}
										{has_joined === true && curr_quiz_id === _id
											? "JOINED"
											: entry_fee}
									</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Confirmation popup */}
			<div
				className="offcanvas offcanvas-bottom cstm_btm_mdl"
				id="offcanvasBottom"
				aria-labelledby="offcanvasBottomLabel"
			>
				<div className="m_s_header pb-2">
					<div className="msh_box left">
						<h3>Confirmation</h3>
						<p style={{ fontSize: "small" }}>
							Current Balance : {token_count ? token_count.slice(0, -18) : "NA"}{" "}
							Token(s)
						</p>
					</div>
					<div className="msh_box right">
						<button
							type="button"
							className="btn-close text-reset"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
						>
							<img src="image/cross_icon.png" alt="X" />
						</button>
					</div>
				</div>

				<div className="offcanvas-body pt-0">
					<div className="jn_cntst_mdl">
						<div className="jn_cnt_contnt">
							<div className="jncc_rptr">
								<div className="jncc_bx left">Joining Fees :</div>
								<div className="jncc_bx right">{entry_fee}</div>
							</div>
							<div className="jncc_rptr">
								<div className="jncc_bx left">Discount : </div>
								<div className="jncc_bx right">{discount}</div>
							</div>
						</div>

						<div className="green_bg cash_bonus">
							<div className="jncc_rptr">
								<div className="jncc_bx left">
									<h3>Usable Cash Bonus : </h3>
									<p>
										You can use Cash Bonus in contest joining fees but it will
										not be more than 20% of the joining fees.
									</p>
								</div>
								<div className="jncc_bx right">{bonus}</div>
							</div>
						</div>

						<div className="jn_cnt_contnt">
							<div className="jncc_rptr">
								<div className="jncc_bx left">To Pay : </div>
								<div className="jncc_bx right">{entry_fee - discount - bonus}</div>
							</div>
						</div>

						<div className="btn_box">
							<button
								onClick={onSelection}
								type="button"
								className="green_btn_grdt"
								data-bs-toggle="offcanvas"
								data-bs-target="#GameStartingLoader"
								aria-controls="offcanvasBottom"
							>
								Join Contest
							</button>
						</div>

						<div className="mdl_note">
							<p>
								While join contest you provide your consent on T&C of OneTo11 &
								confirm to be a non-resident of Assam, Odisha, Telangana & Nagaland
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* Loader */}
			<div
				className="offcanvas offcanvas-bottom blue_bg_modal"
				id="GameStartingLoader"
				aria-labelledby="offcanvasBottomLabel"
			>
				<div className="offcanvas-body">
					<div className="gs_ldr">
						<h3 className="spinner">
							{payment_result === null
								? "Awaiting Payment confirmation"
								: payment_result === false
								? "Error in payment"
								: "Contest has been Joined"}
							{/* <span className="dflt_loader">
								<img src="image/blue_loader.png" alt="O" />
							</span> */}
						</h3>
					</div>
					<div className="gs_content">
						<h3>
							<p className="text-white text-decoration-none">
								{payment_result === null
									? "Please wait while transaction is being done.."
									: payment_result === false
									? "Please retry entering the contest again."
									: "Please Wait till the contest begins.."}
							</p>
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

QuizItem.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setCurrQuizID: PropTypes.func.isRequired,
	setCurrQuiz: PropTypes.func.isRequired,
	setPlayersCount: PropTypes.func.isRequired,
	addParticipation: PropTypes.func.isRequired,
	setHasJoined: PropTypes.func.isRequired,
	quiz: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	curr_contest: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	curr_contest: state.curr_contest,
});

export default connect(mapStateToProps, {
	setAlert,
	setCurrQuizID,
	setCurrQuiz,
	setPlayersCount,
	addParticipation,
	setHasJoined,
})(QuizItem);
