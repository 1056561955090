import {
	ADD_PARTICIPATION,
	SET_CURR_QUIZ,
	SET_CURR_QUIZ_ID,
	SET_CURR_QUESTION_ID,
	SET_PLAYERS,
	SET_HAS_JOINED,
	SET_CURR_ANSWER_KEY,
	SET_ANSWERS,
	UPDATE_ANSWERS,
	QUIZ_ERROR,
	CLEAR_CURR_ANSWER_KEY,
	CLEAR_LIVE_CONTEST,
	SET_RESULT,
} from "../actions/types";

const initialState = {
	participation_id: null,
	curr_quiz_id: null,
	curr_quiz: null,
	has_joined: false,
	currP: null,
	maxP: null,
	curr_question_id: null,
	curr_answer_key: null,
	submitted_answers: [],
	loading: true,
	finished: false,
	reward: null,
	total_count: null,
	correct_count: null,
	error: {},
};

const curr_contest = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ADD_PARTICIPATION:
			return {
				...state,
				participation_id: payload,
				loading: false,
				finished: false,
			};
		case SET_CURR_QUIZ_ID:
			return {
				...state,
				curr_quiz_id: payload,
				loading: false,
				finished: false,
			};
		case SET_PLAYERS:
			return {
				...state,
				currP: payload.curr_players,
				maxP: payload.max_players,
			};
		case SET_HAS_JOINED:
			return {
				...state,
				has_joined: true,
			};
		case SET_CURR_QUIZ:
			return {
				...state,
				curr_quiz: payload,
				loading: false,
				finished: false,
			};
		case SET_CURR_QUESTION_ID:
			return {
				...state,
				curr_question_id: payload,
				loading: false,
				finished: false,
			};
		case SET_CURR_ANSWER_KEY:
			return {
				...state,
				curr_answer_key: payload,
				loading: false,
			};
		case CLEAR_CURR_ANSWER_KEY:
			return {
				...state,
				curr_answer_key: null,
				loading: false,
			};
		case SET_ANSWERS:
			return {
				...state,
				submitted_answers: [...state.submitted_answers, payload],
				loading: false,
			};
		case UPDATE_ANSWERS:
			return {
				...state,
				submitted_answers: state.submitted_answers.map((ans) =>
					ans.question_id === payload.question_id ? { ...ans, key: payload.key } : ans
				),
				loading: false,
			};
		case CLEAR_LIVE_CONTEST:
			return {
				...state,
				curr_question_id: null,
				curr_answer_key: null,
				submitted_answers: [],
				loading: false,
				finished: true,
			};
		case SET_RESULT:
			return {
				...state,
				reward: payload.reward,
				total_count: payload.submitted_answers.length,
				correct_count: payload.correct_ans_count,
			};
		case QUIZ_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default curr_contest;
