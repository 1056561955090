import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// import Alert from "../layout/Alert";

const BeforeTimer = ({ auth: { user }, curr_contest: { curr_quiz } }) => {
	const real_time = new Date();
	const start_time = new Date(curr_quiz.start_time);

	const [timeLeft, setTimeLeft] = useState(
		Math.max(Math.floor((start_time - real_time) / 1000), 0)
	);
	useEffect(() => {
		setTimeout(() => {
			// setTimeLeft(Math.max(timeLeft - 1, 0));
			setTimeLeft(Math.max(Math.floor((start_time - new Date()) / 1000), 0));
		}, 1000);
	});

	// On Tab Focus Change
	// const onTabFocus = () => {
	// 	console.log("IN FOCUS");
	// 	setTimeLeft(Math.max(Math.floor((start_time - new Date()) / 1000), 0));
	// };
	// const onTabBlur = () => {
	// 	console.log("IN BLUR");
	// };

	// useEffect(() => {
	// 	window.addEventListener("focus", onTabFocus);
	// 	window.addEventListener("blur", onTabBlur);

	// 	return () => {
	// 		window.removeEventListener("focus", onTabFocus);
	// 		window.removeEventListener("blur", onTabBlur);
	// 	};
	// }, []);

	const [redirectNow, setRedirectNow] = useState(false);
	useEffect(() => {
		if (timeLeft === 0) {
			setRedirectNow(true);
		}
	}, [timeLeft]);

	return redirectNow ? (
		<Redirect to="/livecontest" />
	) : (
		<Fragment>
			<section className="timer_m h-100vh">
				<div className="timer_box">
					<span style={{ color: "#111111", textDecoration: "none" }}>
						<div className="time_cnt">
							<h3>{timeLeft}</h3>
						</div>
						<div className="time-Title">
							<h3>Searching for opponents . . . </h3>
						</div>
					</span>
				</div>
			</section>
		</Fragment>
	);
};

BeforeTimer.propTypes = {
	auth: PropTypes.object.isRequired,
	curr_contest: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	curr_contest: state.curr_contest,
});

export default connect(mapStateToProps)(BeforeTimer);
