import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import Web3 from "web3";
import { login } from "../../actions/auth";

// import token from "../../token.json";

const Landing = ({ login, isAuthenticated }) => {
	const [web3, setWeb3] = useState(null);
	const [accountsData, setAccountsData] = useState(null);

	const loadWeb3 = async () => {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
		}
		if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			return window.alert("Please install MetaMask!");
		}
		setWeb3(window.web3);
		const web3 = window.web3;
		// const accounts = await web3.eth.getAccounts();
		const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
		setAccountsData(accounts);

		// const contract = new web3.eth.Contract(token, "0xeaA2F739552A4E10b336CD09142BCaAd20EA1d3d");
		// const token_count = await contract.methods.balanceOf(accounts[0]).call();
		// console.log(token_count);

		// const res = await contract.methods
		// 	.transfer(
		// 		"0x96Ad701645F0112322E9C6fc879FD97b82A4DF44",
		// 		"3000000".toString() + "000000000000000000"
		// 	)
		// 	.send({ from: accounts[0] });
		// console.log(res);

		login(accounts[0]);
	};

	if (isAuthenticated) {
		// redirect '/' to '/dashboard' if logged in
		return <Redirect to="/dashboard" />;
	}

	return (
		<div className="login_page defauld_bg">
			<div className="container position-relative">
				<div className="loginbox">
					<div className="top-header">
						<div className="l_back_button">
							<a href="#1">
								<img src="image/ic_back.png" alt="" />
							</a>
						</div>
						<div className="text-center">
							<img src="image/logo.png" alt="Alternate Text" />
						</div>
					</div>

					<div className="login_mat_dtl">
						<a href="#!">
							<span>
								<img src="image/trustwallet_icon.png" alt="Trustwallet" />
							</span>
							Login With Trust Wallet
						</a>
						<a href="#!">
							<span>
								<img src="image/metamask_icon.png" alt="Metamask" />
							</span>
							<button
								onClick={() => loadWeb3()}
								type="button"
								className="login-button"
							>
								Login with Metamask
							</button>
						</a>
						<a href="#!">
							<span>
								<img src="image/mail_icon.png" alt="mail" />
							</span>
							Login With email & password
						</a>
					</div>

					<div className="footer_bottom position-relative">
						<p>
							By logging in you agree to the
							<br />
							<span className="color-yellow">Terms & Conditions</span> and{" "}
							<span className="color-yellow">Privacy Policy </span>
						</p>
					</div>
				</div>
			</div>
			<footer className="footer">
				<p>
					Don't Know Where to Start?{" "}
					<a className="color-yellow" href="#!">
						Getting Started
					</a>
				</p>
			</footer>
		</div>
	);
};

Landing.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Landing);
