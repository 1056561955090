export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const GET_QUIZES = "GET_QUIZES";
export const ADD_PARTICIPATION = "ADD_PARTICIPATION";
export const CLEAR_QUIZ = "CLEAR_QUIZ";
export const QUIZ_ERROR = "QUIZ_ERROR";
export const SET_HAS_JOINED = "SET_HAS_JOINED";
export const SET_CURR_QUIZ_ID = "SET_CURR_QUIZ_ID";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_CURR_QUIZ = "SET_CURR_QUIZ";
export const SET_CURR_QUESTION_ID = "GET_CURR_QUESTION_ID";
export const SET_CURR_ANSWER_KEY = "SET_CURR_ANSWER";
export const CLEAR_CURR_ANSWER_KEY = "CLEAR_CURR_ANSWER_KEY";
export const SET_ANSWERS = "SET_ANSWERS";
export const UPDATE_ANSWERS = "UPDATE_ANSWERS";

export const SET_RESULT = "SET_RESULT";
export const CLEAR_LIVE_CONTEST = "CLEAR_LIVE_CONTEST";
