import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard";
import LiveContest from "../livecontest/LiveContest";
import ContestEnd from "../livecontest/ContestEnd";
import BeforeTimer from "../livecontest/BeforeTimer";
import AfterTimer from "../livecontest/AfterTimer";
import PrivateRoute from "../routing/PrivateRoute";

const Routes = () => {
	return (
		<Switch>
			<PrivateRoute exact path="/dashboard" component={Dashboard} />
			<PrivateRoute exact path="/livecontest" component={LiveContest} />
			<PrivateRoute exact path="/contestend" component={ContestEnd} />
			<PrivateRoute exact path="/beforetimer" component={BeforeTimer} />
			<PrivateRoute exact path="/aftertimer" component={AfterTimer} />
		</Switch>
	);
};

export default Routes;
