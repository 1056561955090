import { USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGOUT } from "../actions/types";

const initialState = {
	isAuthenticated: null,
	loading: true,
	user: null,
	contract: null,
	token_count: null,
};

const auth = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload.accountData,
				contract: payload.contract,
				token_count: payload.token_count,
			};
		case LOGIN_SUCCESS:
			localStorage.setItem("user", payload);
			return {
				...state,
				isAuthenticated: true,
				loading: false,
			};
		case AUTH_ERROR:
		case LOGOUT:
			localStorage.removeItem("user");
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				user: null,
			};
		default:
			return state;
	}
};

export default auth;
