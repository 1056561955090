import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";

import { getParticipationByID } from "../../actions/curr_contest";

const ContestEnd = ({
	auth: { user },
	curr_contest: { curr_quiz, participation_id, finished, reward, total_count, correct_count },
	getParticipationByID,
}) => {
	useEffect(() => {
		if (participation_id !== null) {
			getParticipationByID(participation_id);
		}
	}, [getParticipationByID, participation_id]);

	return finished ? (
		<Fragment>
			<section className=" h-100vh">
				<div className="container">
					<div className="qs_header">
						<div className="l_back_button" style={{ left: "auto" }}>
							<a href="/dashboard">
								<img src="image/ic_back.png" alt="back" />
							</a>
						</div>
					</div>
				</div>
				<div className="center-screen contest-ended-center">
					{reward === null ? (
						<Spinner />
					) : reward > 0 ? (
						<div className="success_result">
							<img
								src="image/img_congratulations.png"
								className="con_img"
								alt="congo"
							/>
							<img src="image/coins.png" style={{ maxWidth: "120px" }} alt="coin" />
							<h3 style={{ color: "white" }}>You won</h3>
							<h1 style={{ color: "white" }}>{reward} Token(s)</h1>
						</div>
					) : (
						<div className="failed_result">
							<img src="image/sad.png" style={{ maxWidth: "120px" }} alt="sad" />
							<h1 style={{ color: "white" }}>Sorry!</h1>
							<h3 style={{ color: "white" }}>Better Luck Next Time</h3>
						</div>
					)}
					<div className="result_section">
						<h4>Result</h4>

						<table>
							<tbody>
								<tr>
									<td>Correct Answer</td>
									<td style={{ color: "#449812" }}>{correct_count}</td>
								</tr>
								<tr>
									<td>Incorrect Answer</td>
									<td style={{ color: "#B01818" }}>
										{curr_quiz.questions.length - correct_count}
									</td>
								</tr>
								<tr>
									<td>Total Question</td>
									<td>{curr_quiz.questions.length}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</Fragment>
	) : (
		<Fragment>
			<section className="question_set h-100vh">
				<div className="container" style={{ marginTop: "6rem" }}>
					<Spinner />
				</div>
			</section>
		</Fragment>
	);
};

ContestEnd.propTypes = {
	getParticipationByID: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	curr_contest: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	curr_contest: state.curr_contest,
});

export default connect(mapStateToProps, { getParticipationByID })(ContestEnd);
