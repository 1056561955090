import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import QuizItem from "./QuizItem";

import { loadUser } from "../../actions/auth";
import { getQuizes } from "../../actions/quiz";
import Navbar from "../layout/Navbar";

import Web3 from "web3";
import token from "../../token.json";

const Dashboard = ({
	auth: { user, isAuthenticated },
	quiz: { quizes, loading },
	getQuizes,
	loadUser,
}) => {
	const [web3, setWeb3] = useState(null);
	const [accountsData, setAccountsData] = useState(null);

	const loadWeb3 = async () => {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
		}
		if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			return window.alert("Please install MetaMask!");
		}
		setWeb3(window.web3);
		const web3 = window.web3;
		// const accounts = await web3.eth.getAccounts();
		// const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
		// setAccountsData(accounts);
		setAccountsData(localStorage.user);

		const contract = new web3.eth.Contract(token, "0x4D52DD06Aea3DeE1431D40F6a12733C6EcbBC845");
		const token_count = await contract.methods.balanceOf(localStorage.user).call();
		// console.log(token_count);

		loadUser(localStorage.user, token_count, contract);
	};

	useEffect(() => {
		loadWeb3();
		getQuizes();
	}, [getQuizes]);

	// if (isAuthenticated === null) {
	// 	return <Redirect to="/" />;
	// }

	return loading ? (
		<section className="container">
			<Spinner />
		</section>
	) : (
		<Fragment>
			<Navbar />
			<Alert />
			<section className="container">
				<img
					src="https://oneto11storageaccount.blob.core.windows.net/oneto11quizdemo/banner.png"
					className="img-fluid"
					alt="banner"
				/>
				<div className="cntlst_box">
					<div className="posts">
						{quizes &&
							quizes.contests.docs.map((quiz) => (
								<QuizItem key={quiz._id} quiz={quiz} />
							))}
					</div>
				</div>
			</section>
		</Fragment>
	);
};

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired,
	getQuizes: PropTypes.func.isRequired,
	quiz: PropTypes.object.isRequired,
	loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	quiz: state.quiz,
});

export default connect(mapStateToProps, { getQuizes, loadUser })(Dashboard);
