import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

// import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Routes from "./components/routing/Routes";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

import "./App.css";

function App() {
	if (localStorage.user) {
		store.dispatch(loadUser(localStorage.user));
	}

	// Test if correct API URL is loaded
	// console.log(process.env.REACT_APP_API_URL);
	return (
		<Provider store={store}>
			<Router>
				<Fragment>
					<Switch>
						<Route exact path="/" component={Landing} />
						<Route component={Routes} />
					</Switch>
				</Fragment>
			</Router>
		</Provider>
	);
}

export default App;
