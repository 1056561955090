import axios from "axios";
// import { setAlert } from "./alert";

import {
	SET_CURR_QUIZ,
	SET_CURR_QUIZ_ID,
	SET_PLAYERS,
	ADD_PARTICIPATION,
	SET_HAS_JOINED,
	SET_CURR_QUESTION_ID,
	SET_CURR_ANSWER_KEY,
	CLEAR_CURR_ANSWER_KEY,
	SET_ANSWERS,
	SET_RESULT,
	QUIZ_ERROR,
	CLEAR_LIVE_CONTEST,
} from "./types";

const API_URL = process.env.REACT_APP_API_URL;

// Get quiz by id
export const setCurrQuiz = (id) => async (dispatch) => {
	try {
		const res = await axios.get(`${API_URL}/contest/${id}`);
		dispatch({
			type: SET_CURR_QUIZ,
			payload: res.data.foundContest,
		});
	} catch (error) {
		dispatch({
			type: QUIZ_ERROR,
			payload: error,
		});
	}
};

export const setCurrQuizID = (id) => async (dispatch) => {
	try {
		dispatch({ type: SET_CURR_QUIZ_ID, payload: id });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const setPlayersCount = (curr_players, max_players) => async (dispatch) => {
	try {
		dispatch({
			type: SET_PLAYERS,
			payload: {
				curr_players,
				max_players,
			},
		});
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const setHasJoined = () => async (dispatch) => {
	try {
		dispatch({ type: SET_HAS_JOINED, payload: null });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const setCurrQuestionID = (id) => async (dispatch) => {
	try {
		dispatch({ type: SET_CURR_QUESTION_ID, payload: id });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const setCurrAnswerKey = (key) => async (dispatch) => {
	try {
		dispatch({ type: SET_CURR_ANSWER_KEY, payload: key });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

//Add participation
export const addParticipation = ({ user_address, contest_id }) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	// console.log(user_address, contest_id);
	const body = JSON.stringify({ user_address, contest_id });

	try {
		const res = await axios.post(`${API_URL}/participation`, body, config);

		dispatch({
			type: ADD_PARTICIPATION,
			payload: res.data.participation._id,
		});
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const setAnswers = (data) => async (dispatch) => {
	try {
		dispatch({ type: SET_ANSWERS, payload: data });
		dispatch({ type: CLEAR_CURR_ANSWER_KEY, payload: null });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const recordParticipation = ({ participation_id, recorded_answers }) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	// console.log(participation_id, recorded_answers);
	const body = JSON.stringify({ recorded_answers });
	try {
		const res = await axios.post(`${API_URL}/participation/${participation_id}`, body, config);
		// console.log(res.data);
		dispatch({ type: CLEAR_LIVE_CONTEST, payload: null });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};

export const getParticipationByID = (participation_id) => async (dispatch) => {
	try {
		const res = await axios.get(`${API_URL}/participation/${participation_id}`);
		// console.log(res.data);
		dispatch({ type: SET_RESULT, payload: res.data.foundParticipation });
	} catch (error) {
		dispatch({ type: QUIZ_ERROR });
		console.log(error);
	}
};
