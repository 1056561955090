import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import Alert from "../layout/Alert";
import { Link, Redirect } from "react-router-dom";
import {
	setCurrQuestionID,
	setCurrAnswerKey,
	setAnswers,
	recordParticipation,
} from "../../actions/curr_contest";

const LiveContest = ({
	auth: { user },
	curr_contest: {
		participation_id,
		curr_quiz_id,
		curr_quiz,
		curr_answer_key,
		curr_question_id,
		submitted_answers,
		finished,
	},
	setCurrQuestionID,
	setCurrAnswerKey,
	setAnswers,
	recordParticipation,
}) => {
	// const [showQuestion, setShowQuestion] = useState(false);
	const [currIdx, setCurrIdx] = useState(0);
	const [submitPressed, setSubmitPressed] = useState(false);

	const totalQues = curr_quiz ? curr_quiz.questions.length : 0;

	const real_time = new Date();
	const end_time = new Date(curr_quiz.start_time);
	end_time.setSeconds(end_time.getSeconds() + 10 * totalQues + 4);

	const [timeLeft, setTimeLeft] = useState(
		Math.max(Math.floor((end_time - real_time) / 1000), 0)
	);

	const [selectedKey, setSelectedKey] = useState(null);

	useEffect(() => {
		var timer3 = setTimeout(() => {
			setTimeLeft(Math.max(Math.floor((end_time - new Date()) / 1000), 0));
		}, 1000);
		return () => clearTimeout(timer3);
	});

	//NOTE :COMMENTED FOR TESTING, UNCOMMENT IN PROD
	useEffect(() => {
		if (timeLeft === 0) {
			recordParticipation({
				participation_id: participation_id,
				recorded_answers: submitted_answers,
			});
		}
	}, [timeLeft]);

	const findQuestionID = (i) => {
		var ques = curr_quiz.questions.reduce(function (prev, current, index) {
			return index === i ? current : prev;
		}, undefined);
		return ques._id;
	};
	const updateQuestionID = (id) => {
		setCurrQuestionID(id);
	};

	useEffect(() => {
		updateQuestionID(findQuestionID(0));
	}, []);

	//NOTE :COMMENTED FOR TESTING, UNCOMMENT IN PROD
	useEffect(() => {
		if (submitted_answers.length === totalQues) {
			recordParticipation({
				participation_id: participation_id,
				recorded_answers: submitted_answers,
			});
		}
	}, [submitted_answers, recordParticipation, totalQues]);

	if (curr_quiz_id === null) {
		return <Redirect to="/dashboard" />;
	}

	if (finished === true) {
		return <Redirect to="/aftertimer" />;
	}

	// const findSubmittedAnswer = (id) => {
	// 	console.log(submitted_answers, id);
	// 	var obj = submitted_answers.reduce(function (prev, current, index) {
	// 		return current.question_id === id ? current : prev;
	// 	}, undefined);

	// 	console.log(obj);
	// };

	// const findSubmittedAnswerHandler = () => {
	// 	findSubmittedAnswer(findQuestionID(currIdx - 1));
	// };

	// const prevHandler = () => {
	// 	updateQuestionID(findQuestionID(currIdx - 1));
	// 	setCurrIdx(currIdx - 1);
	// };

	const selectHandler = (key) => {
		setSelectedKey(key);
		setCurrAnswerKey(key);
	};

	const nextHandler = () => {
		// findSubmittedAnswerHandler();
		setAnswers({ question_id: curr_question_id, answer_key: curr_answer_key });
		updateQuestionID(findQuestionID(currIdx + 1));
		setCurrIdx(currIdx + 1);
		setSelectedKey(null);
	};

	const submitHandler = () => {
		setAnswers({ question_id: curr_question_id, answer_key: curr_answer_key });
		setSubmitPressed(true);
	};

	return (
		<Fragment>
			<section className="question_set h-100vh">
				<div style={{ padding: "0 2rem" }} className="container position-relative">
					<div className="qs_header">
						<div className="l_back_button">
							<a href="/dashboard">
								<img src="image/ic_back.png" alt="" />
							</a>
						</div>
						<div className="r_setting_button">
							<a href="#!">
								<img src="image/ic_setting.png" alt="" />
							</a>
						</div>
						<div className="qs_tm_s">
							<div className="qs_tm_s_box">
								<span className="qs_tm_s_time">{timeLeft}</span>
								<span className="qs_tm_s_sec">SEC</span>
							</div>
						</div>
						{/* {currIdx > 0 ? (
							<div className="prev-ques-button">
								<button onClick={prevHandler}>PREV</button>
							</div>
						) : (
							<div className="prev-ques-button">
								<button disabled onClick={prevHandler}>
									PREV
								</button>
							</div>
						)} */}
					</div>

					<div className="question_answer">
						<div className="question_box">
							<div className="question_name default_yello_button">
								<div className="qs_titme">
									<h3>Question {currIdx + 1} </h3>
								</div>
								<div className="qs_stp_to_stp">
									<span>{currIdx + 1}</span>
									<span>/{totalQues}</span>
								</div>
							</div>
							<div className="qs_name">
								<img
									src="https://oneto11storageaccount.blob.core.windows.net/oneto11quizdemo/question_bg.png"
									alt=""
								/>
								<h3>
									{curr_quiz.questions.map((item, index) => {
										return index === currIdx ? item.content : "";
									})}
								</h3>
							</div>
						</div>
						<div className="answer_box">
							<div className="ans_box">
								{curr_quiz.questions.map((item, index) => {
									return index === currIdx
										? item.options.map((option) => (
												<div
													onClick={(e) => selectHandler(option.key)}
													key={option.key}
													className="ans_reptr"
												>
													{selectedKey === option.key ? (
														<label className="container_radio">
															<h3>{option.value}</h3>{" "}
															<input
																type="radio"
																checked="checked"
																name="radio"
															/>
															<span className="checkmark"></span>
														</label>
													) : (
														<label className="container_radio">
															<h3>{option.value}</h3>{" "}
															<input type="radio" name="radio" />
															<span className="checkmark"></span>
														</label>
													)}
												</div>
										  ))
										: "";
								})}
							</div>
						</div>

						<div className="footer_btn">
							{curr_answer_key ? (
								<span style={{ color: "gold" }}>
									{/* Selected Answer:{" "}
									<span style={{ fontSize: "34px" }}>{curr_answer_key}</span> */}
								</span>
							) : submitPressed === true ? (
								<span style={{ color: "#85ff33" }}>
									{/* Answers have been submitted! */}
								</span>
							) : (
								<span style={{ color: "red" }}>{/* No answer selected ! */}</span>
							)}
							{currIdx < totalQues - 1 ? (
								<button className="orange_btn" onClick={nextHandler}>
									NEXT
								</button>
							) : (
								<button
									className="orange_btn"
									onClick={(e) => {
										e.target.setAttribute("disabled", true);
										submitHandler();
									}}
								>
									SUBMIT
								</button>
							)}
						</div>

						{/* <div className="submit-next" style={{ fontSize: "x-large" }}>
						
						</div> */}
					</div>
				</div>
			</section>
		</Fragment>
	);
};

LiveContest.propTypes = {
	setCurrQuestionID: PropTypes.func.isRequired,
	setCurrAnswerKey: PropTypes.func.isRequired,
	setAnswers: PropTypes.func.isRequired,
	recordParticipation: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	curr_contest: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	curr_contest: state.curr_contest,
});

export default connect(mapStateToProps, {
	setCurrQuestionID,
	setCurrAnswerKey,
	setAnswers,
	recordParticipation,
})(LiveContest);
