import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../actions/auth";

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
	const authLinks = (
		<ul className="h_right">
			<li>
				<a onClick={logout} href="#!">
					<i className="fas fa-sign-out-alt"></i> <span className="hide-sm">Logout</span>
				</a>
			</li>
		</ul>
	);

	return (
		<nav style={{ display: "flex", maxWidth: "500px" }} className="navbar bg-dark">
			<div className="h_l_menu">
				<a href="#!">
					<span className="s_profile_pic">
						<img src="image/pro_pic.png" alt="" />
					</span>
					<span className="l_menu_icon">
						<img src="image/menu_bar.png" alt="" />
					</span>
				</a>
			</div>
			
				<Link to="/">
					<img className="h_logo" src="image/logo.png" alt="" />
				</Link>
			
			{!loading && <Fragment>{isAuthenticated && authLinks}</Fragment>}
		</nav>
	);
};

Navbar.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
