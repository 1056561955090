// import axios from "axios";
import { setAlert } from "./alert";
import { USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGOUT } from "./types";

//Load user
export const loadUser = (accountData, token_count, contract) => async (dispatch) => {
	try {
		dispatch({
			type: USER_LOADED,
			payload: {
				accountData,
				token_count,
				contract,
			},
		});
	} catch (error) {
		dispatch({ type: AUTH_ERROR });
	}
};

//Login user
export const login = (accountData) => async (dispatch) => {
	try {
		dispatch({
			type: LOGIN_SUCCESS,
			payload: accountData,
		});

		// dispatch(loadUser(accountData, token_count, contract));
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// Logout
export const logout = () => (dispatch) => {
	dispatch({ type: LOGOUT });
};
