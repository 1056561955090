import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) =>
	alerts !== null &&
	alerts.length > 0 &&
	alerts.map((alert) => (
		<div key={alert.id} className={`alert alert-${alert.alertType} blue_bg_modal`}>
			<div className="offcanvas-body">
				<div className="gs_ldr">
					<h3 className="spinner">{alert.msg}</h3>
				</div>
			</div>
		</div>
	));

Alert.propTypes = {
	alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
	alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
