import axios from "axios";
// import { setAlert } from "./alert";

import { GET_QUIZES, QUIZ_ERROR } from "./types";

const API_URL = process.env.REACT_APP_API_URL;

// Get all quizes
export const getQuizes = () => async (dispatch) => {
	try {
		const res = await axios.get(`${API_URL}/contest`, {
			params: {
				limit: 30,
			},
		});

		dispatch({
			type: GET_QUIZES,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: QUIZ_ERROR,
			payload: err,
		});
		console.log(err);
	}
};
