import { combineReducers } from "redux";

import auth from "./auth";
import alert from "./alert";
import quiz from "./quiz";
import curr_contest from "./curr_contest";

export default combineReducers({
	auth,
	alert,
	quiz,
	curr_contest,
});
